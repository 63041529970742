import React from 'react';
import clsx from 'clsx';
import { AppBar } from 'react-admin';
import { Hidden, Typography } from '@material-ui/core';

import { ProfileMenu } from '../menus';
import { appBarStyles } from '../../stylesheets';

interface Props {
  open: boolean;
  classes: object;
  className: string;
  logout: React.ReactElement;
  userMenu: React.ReactElement;
}

export const AbAppBar: React.FC<Props> = props => {
  const classes = appBarStyles();
  const userProgram = sessionStorage.getItem('userProgram');
  const saccoName = sessionStorage.getItem('saccoName');

  return (
    <AppBar 
      {...props} 
      className={classes.appBar}
      userMenu={<ProfileMenu />}
    >
      <Typography
        color="inherit"
        className={clsx(classes.title, 'app-title')}
        id="react-admin-title"
      />
      <Hidden mdDown>
        <Typography variant="h6" color="inherit">
          AkelloBanker {!!userProgram ? `- ${userProgram}` : ''} {!!saccoName ? `/ ${saccoName}` : ''}
        </Typography>        
      </Hidden>
      <span className={classes.spacer} />
    </AppBar>
  );
};
