import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';

import { Dialog, DialogContent, DialogTitle, useMediaQuery } from '@material-ui/core';
import { required, SaveButton, SimpleForm, SelectInput, Toolbar, useRefresh, useNotify } from 'react-admin';

import { SectionTitle, Transition } from '../../modules';
import { ApproveButton as NoButton } from '../../layout';

import { AppContext } from '../../context/app';

export const DialogSwitchProgram = props => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const {
    history,
    onClose,
    userPrograms,
     ...rest 
  } = props;

  // populate "select" list
  const programs = JSON.parse(userPrograms);

  const [open, setOpen] = React.useState(true);

  const handleClose = React.useCallback(() => {
    setOpen(!open);
    history.push(history.location.pathname);
  }, [history, open]);

  const isHome = history.pathname === '/' ? true : false;

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={clsx('request--modal')}
      aria-labelledby="switch-pro-dialog-title"
    >
      <DialogTitle
        disableTypography={true} // makes this a <div> not a default <h2>
        id="switch-pro-dialog-title"
      >
        <SectionTitle label="Switch program..." />
      </DialogTitle>
      <DialogContent>
        <SimpleForm 
          {...rest}
          className={clsx( 'request','switch', props.className )}
          submitOnEnter={false} 
          toolbar={
            <SwitchProgramToolbar
              userPrograms={userPrograms}
              onClose={handleClose}
              isXSmall={isXSmall}
              isHome={isHome}
              {...rest}
            />
          }
        >
					<SelectInput
						label="Program"
						source="selected_program" // used in program change, so it's required
						optionText="short_name"
						choices={programs}
						className={clsx('add-to-program')}
						validate={[required()]}
					/>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

// Handling "switch program" mode
const SwitchProgramToolbar = props => {

  // destructure to pass directly to <ApproveButton>
  const { isXSmall, ...rest } = props;
  const { onClose, userPrograms, isHome, ...other } = rest;

  return (
    <Toolbar {...other}>
      <NoButton
        label="Cancel"
        icon={null}
        isXSmall={isXSmall}
        className={clsx('small--btn')}
        onClick={onClose}
      />
      <SwitchProgramButton {...rest} /> 
    </Toolbar>
  )  
};

const SwitchProgramButton = props => {
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();

  const { selected_program } = formState && formState.values;

  // destructure to pass "rest" to <SaveButton>
  const { isHome, onClose, mutationMode, userPrograms, validating, ...rest } = props;

  const { setSwitchCardProgram, setSwitchChartsProgram } = React.useContext(AppContext);

  const [ loading, setLoading ] = React.useState(false); // eslint-disable-line

  const handleSwitchProgram = React.useCallback(() => {

    if (selected_program) {

      // check that "selected program" isn't same as currently active program
      if ( selected_program.toString() !== sessionStorage.getItem('userProgramId').toString() ) {

        // set the "selected program" to be activated
        sessionStorage.setItem('userProgramId', selected_program);
        
        // and set the new activated "program name"
        JSON.parse(userPrograms).map(
          ({ id, program_name }) => (
            id.toString() === selected_program.toString()
              ? sessionStorage.setItem('userProgram', program_name)
              : null
        ));

        // used within "info" notice, below.
        let userProgram = sessionStorage.getItem('userProgram');
  
        setLoading(!loading);
        onClose();
        refresh();

        isHome && setSwitchCardProgram(true);   /* for dashboard card   - loaders */
        isHome && setSwitchChartsProgram(true); /* for dashboard charts - loaders */

        notify(`Switching to "${userProgram}" program...`, 'info');

      } else {

        // used within "warning" notice, below.
        let userProgram = sessionStorage.getItem('userProgram');

        notify(`"${userProgram}" is already active.`, 'warning');
      };    

    } else {

      // if "selected program" is undefined/null, just close the dialog
      onClose();
      notify(`Something went wrong, try again later.`, 'warning');
    };

  }, [selected_program]); // eslint-disable-line react-hooks/exhaustive-deps

  // define a component that returns "null"
  // And pass it to <SaveButton> to override the "save" icon
  const NoIcon = () => null;

  return (
    <SaveButton
      {...rest}
      icon={<NoIcon />}
      label={loading ? "" : "Switch"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleSwitchProgram} 
    />
  );
};
