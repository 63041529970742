import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Theme, useMediaQuery } from '@material-ui/core';
import { usePermissions } from 'react-admin';

import AccountantDashboard from './accountant';
import SuperuserDashboard from './superuser';
import ProviderDashboard from './provider';
import SaccoAdminDashboard from './admin';
import AgentDashboard from './agent';

import { AppState } from 'types'
import * as P from 'allPermissions';
export * from './DashboardSettings'; // used in "custom routes"

interface CountStats {
  users?: number;
  farmers?: number;
  providers?: number;
  sumfarmerRequests?: number;
  unapprovedProviderRequests?: number;
  newSaccoUsers?: number;
  anyUpdates?: boolean;
}

export const Dashboard = (props: any) => {
  
  const history = useHistory();
  const { permissions } = usePermissions();

  const userId = sessionStorage.getItem('id');

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isMediumRange = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));

  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const [state, setState] = React.useState<CountStats>({ //eslint-disable-line
    sumfarmerRequests: 0,
    unapprovedProviderRequests: 0,
    newSaccoUsers: 0,
    anyUpdates: false,
  });

  const {
    unapprovedProviderRequests,
    newSaccoUsers,
    anyUpdates,
  } = state;

  if (!permissions) { // if no permissions
    return null;
  };

  const generalProps = {
    isSmall: isSmall,
    isXSmall: isXSmall,
    sidebarOpen: open,
    isMediumRange: isMediumRange,
    ...props
  };

  const saccoProps = {
    anyUpdates: anyUpdates,
    newSaccoUsers: newSaccoUsers,
    ...generalProps
  };

  const superProps = {
    anyUpdates: anyUpdates,
    unapprovedProviderRequests: unapprovedProviderRequests,
    ...generalProps
  };

  const accessProfile = {
    pathname: `/users/${userId}`, 
    hash: '#my-profile', 
    state: { prevPath: history.location.pathname } 
  };

  return (
    P.accessStatsDashboard(permissions)              
      ? <SuperuserDashboard {...superProps}           /* superuser */ />
      : P.provider(permissions)               
        ? <ProviderDashboard {...generalProps}        /* service provider */ />       
        : P.admin(permissions)               
          ? <SaccoAdminDashboard {...saccoProps}      /* sacco-admin */ />
          : P.accountant(permissions)      
            ? <AccountantDashboard {...generalProps}  /* accountant */ />        
            : P.agent(permissions)               
              ? <AgentDashboard {...generalProps}     /* agent */ />
              : P.farmer(permissions)             
                ? <Redirect to={accessProfile}        /* farmer -> only access my-profile */ />
                : null
  );
};
