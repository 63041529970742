import React from 'react';
import clsx from 'clsx';
import { useRouteMatch } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DownloadIcon from '@material-ui/icons/GetApp';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { AsideFields } from './AsideFields';
import { UserProfileFields } from './UserProfileFields';

import maleImage from 'assets/male.png';
import femaleImage from 'assets/female.png';
import { SectionTitle } from 'modules/utils';
import { UserProfilePDF } from 'modules/users/profile';
import { NextButton as EditButton, LinkButton } from 'layout';

const UserImageEdit = React.lazy(() => import("../../userdialogs/userprofile/UserImageEdit"));
const CoordinatorFields = React.lazy(() => import("./CoordinatorFields"));
const PasswordFields = React.lazy(() => import("./PasswordFields"));
const ProfilePhoto = React.lazy(() => import("./AsidePhotoField"));
const FarmerFields = React.lazy(() => import("./FarmerFields"));

export const FieldsLayer = (props: any) => {
  const {
    token,
    record,
    classes,
    isSmall,
    max1220,
    isFarmer,
    basePath,
    onCancel,
    location,
    profileMain,
    isRegistered,
    isCoordinator,
    profilePassword,
  } = props;

  const { image, gender, first_name, user_system_role } = record;
  const _role = user_system_role && user_system_role[0] && user_system_role[0]['name'];
  const farmerRole = _role === "Farmer";
  const myFarmerProfile = profileMain && farmerRole;

  const hasProfilePhoto = record && !!image;
  const Image = !!image ? image : gender && gender === "F" ? femaleImage : maleImage;

  const matchThis = useRouteMatch([ '/accountants/:id', '/agents/:id', '/coordinators/:id', '/members/:id', '/users/:id' ]);
  const matchUserPath = matchThis && matchThis!.isExact; // match either path

  const showFarmerFields = location.hash === "#farmer";
  const showCoordinatorFields = location.hash === "#coordinator";
  const profileFarmerFields = location.hash === "#my-profile-farmer";
  
  const [openImage, setOpenImage] = React.useState(false); // "profile photo" modal state
  const handleCloseImage = () => setOpenImage(!openImage); // "profile photo" modal open/close

  /* for general fields */
  const generalProps = {
    record: record,
    classes: classes,
    isSmall: isSmall,
    profileMain: profileMain,
    isRegistered: isRegistered,
  };

  const profileBtnProps = {
    classes: classes,
    openDialog: openImage,
    handleClose: handleCloseImage,
    userId: record && record['id'],
    hasProfilePhoto: hasProfilePhoto
  };

  const profilePhotoProps = {
    Image: Image, 
    record: record,
    isSmall: isSmall, 
    handleClose: handleCloseImage, 
    hasProfilePhoto: hasProfilePhoto,
  };

  const ProfileHeading = () => {

    // render "title" label
    const showTitle = matchUserPath ? 
      !location.hash ? 'User Profile'
        : (profileMain || profilePassword || profileFarmerFields) ? `Welcome ${first_name},`
          : showFarmerFields ? 'Farmer Data'
            : showCoordinatorFields ? 'Coordinator Data'
              : null
      : null; 

    const currentPath = param => !location.hash && basePath===param;

    // render "user/farmer" buttons
    const ButtonTitle = () => matchUserPath ? 
      (currentPath('/users') || myFarmerProfile) ? <span>Farmer Data</span> 
        : currentPath('/coordinators') ? <span>Coordinator Data</span>
          : (showFarmerFields || showCoordinatorFields || profileFarmerFields) ? <span>User Profile</span>
            : null
      : null; 

    // used within "LinkButton" to switch rendered fields
    const matched = currentPath('/users')
      ? { pathname: location!.pathname, hash: '#farmer' } 
      : myFarmerProfile
        ? { pathname: location!.pathname, hash: '#my-profile-farmer' }
        : profileFarmerFields
          ? { pathname: location!.pathname, hash: '#my-profile' }
          : currentPath('/coordinators')
            ? { pathname: location!.pathname, hash: '#coordinator' }
            : { pathname: location!.pathname, hash: '' };
  
    return (
      <>
        <SectionTitle label={showTitle} className={clsx('AkRegisterForm-title')} />
        {isFarmer && <LinkButton label={<ButtonTitle/>} url={matched} />}
        {!isFarmer && myFarmerProfile && <LinkButton label={<ButtonTitle/>} url={matched} />}
        {!isFarmer && profileFarmerFields && <LinkButton label={<ButtonTitle/>} url={matched} />}
        {isCoordinator && <LinkButton label={<ButtonTitle/>} url={matched} />}
      </>
    )
  };

  // render "readable" fields
  const RenderFields = () => (
    matchUserPath ?
      (!location.hash || profileMain || profilePassword) 
        ? <UserProfileFields {...generalProps} />
        : (showFarmerFields || profileFarmerFields)
          ? isFarmer && <FarmerFields isFarmer={isFarmer} {...generalProps} />
          : showCoordinatorFields
            ? isCoordinator && <CoordinatorFields isCoordinator={isCoordinator} {...generalProps} />
            : null
    : null
  );

  // for password fields
  const passfieldProps = {
    classes: classes,
    isSmall: isSmall, /* < 599.98px */
    token: token,
  };

  return (
    <>
      {/* 
        * SimpleForm expects Input or Field components as children.
        * Explained here: https://marmelab.com/react-admin/CreateEdit.html#the-simpleform-component. 
        * So it's normal that you see warnings because we passed <Box>
        */}

      <Box display={{ xs: 'inline-flex' }} width={{ xs: '100% !important' }} mb={{ xs: '10px' }}>
        <Box flex={1} className={clsx('inline-flex', 'start')}>
          <ProfileHeading />
        </Box>
        <Box>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        display={{ md: 'flex' }}
        className='AkRegisterForm-body'
      >

        <Box
          flex={1}
          className={clsx(
            'AkRegisterForm-body', 'aside-section',
            { 'flex center-justify bg-grey--light mr-15 h-450': !isRegistered }
          )}
        >
          {profilePassword
            ? <PasswordFields {...passfieldProps} />
            : <>
                <ProfilePhoto {...profilePhotoProps} />
                <AsideFields record={record} />
                <ExportBtn isSmall={isSmall} />
              </>}
        </Box>

        <Box
          flex={3}
          className={clsx(
            'detail-section',
            { 'mr-15-negative': !max1220 },
          )}
        >
          <RenderFields/>
        </Box>

        <UserImageEdit {...profileBtnProps} /> {/* Modal triggered in "edit-mode" */}
      </Box>
    </>
  )
};

const ExportBtn = (props: any) => {
  const { isSmall } = props;
  const Export = () => <span>Export Profile</span>;

  return (
    <Box className="mt-14">
      <PDFDownloadLink
        className="export-profile no-underline"
        document={<UserProfilePDF />}
        fileName="somename.pdf"
      >
        {({ blob, url, loading, error }) =>
          !loading &&
            <EditButton
              handleNext={()=>{}}
              label='Export Profile'
              className={clsx('small--btn')}
              icon={isSmall?<Export />:<DownloadIcon />}
            />}
      </PDFDownloadLink>
    </Box>
  )
};
