import React from 'react';
import clsx from 'clsx';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { Box } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { useRefresh, useNotify } from 'react-admin';

import { FetchProgress, NextButton as TitleButton } from 'layout';
import { SectionTitle, Separator } from 'modules/utils';
import { apiFullCall } from 'modules/apiHelper';

const UserDetailsEdit = React.lazy(() => import("../../userdialogs/userprofile/UserDetailsEdit"));
const ContactInfoEdit = React.lazy(() => import("../../userdialogs/userprofile/ContactInfoEdit"));
const AddressInfoEdit = React.lazy(() => import("../../userdialogs/userprofile/AddressInfoEdit"));
const IdentityInfoEdit = React.lazy(() => import("../../userdialogs/userprofile/IdentityInfoEdit"));

export const UserProfileFields = props => {

  const {
    record,
    isSmall,
    profileMain
  } = props;

  const matchUser = useRouteMatch('/users/:id');
  const matchAccountant = useRouteMatch('/accountants/:id');
  const matchAgent = useRouteMatch('/agents/:id');
  const token = sessionStorage.getItem('token');
  let isLoaded = React.useRef(true);
  const refresh = useRefresh();
  const notify = useNotify();

  const [rafields, setRaFields] = React.useState<object | any>({
    box_number: '', 
    physical_address: '', 
    villageid: '',
  });

  const [filterValue, setFilterValue] = React.useState<object | any>({
    id: '',
    villagename: '',
    parishname: '',
    subcountyname: '',
    countyname: '',
    districtname: '',
    regionname: '',
  });

  let location = { ...filterValue, ...rafields };

  React.useEffect(() => {

    if (isLoaded) {

      apiFullCall(
        '', 
        token,
        'get', 
        `usersview/${record['id']}` 
      ).then(res => {

        if (res) {

          const { status, body } = res;

          if (status === 200 || status === 201) {

            const { 
              villageid,
              villagename,
              parishname,
              subcountyname,
              countyname,
              districtname,
              regionname,
              box_number,
              physical_address,
              ...rest // eslint-disable-line
            } = body;

            // set fields, to be used in API call (save buttons)
            // check if not "null" to avoid setting value=null in AkTextFields
            if (!!villageid) {

              setRaFields({
                ...rafields,
                box_number: !!box_number ? box_number : "", 
                physical_address: !!physical_address ? physical_address : "",
                villageid: villageid 
              });

            };

            const data = {
              villagename: villagename,
              parishname: parishname,
              subcountyname: subcountyname,
              countyname: countyname,
              districtname: districtname,
              regionname: regionname
            };

            setFilterValue({ ...data });

          } else if (status === 404) { // Not found

            // "villageid" manually assigned to stop "loader"
            setRaFields({ ...rafields, villageid: '0' }); 
            
          } else if (status >= 500) {

            if (!body) { // we have "null" thus this user has 2 or more location records

              apiFullCall(
                '', 
                token,
                'get', 
                `usersaddress/?user_id=${record['id']}` 
              ).then(res => {

                if (res) {
                  const { status, body } = res;

                  if (status === 200 || status === 201) {
                    
                    const { results } = body;
                                        
                    results
                      // we remove results[0] and delete the rest
                      && results.shift()
                        // The remaining locations are in results[]
                        && results.map(location =>
                          // and loop through the list and "delete" other locations
                          apiFullCall(
                            '', 
                            token,
                            'delete', 
                            `usersaddress/${location['id']}` 
                          ).then(
                            res => res
                          ).catch(
                            error => console.error('Error while saving:', error)
                          ) 
                        );

                    // We trigger a reload state to render "only" location
                    refresh();  
                    
                  } else {

                    notify(`Server error, please try again later.`, 'warning');
                  };

                };

              }).catch(
                error => console.error('Error while fetching address:', error)
              );

            } else {

              notify(`Server error, please try again later.`, 'warning');          
            }
          };

        };

      }).catch(
        error => console.error('Error while fetching address:', error)
      );

    };

    // clean up API call, on unmount
    return function cleanup() { isLoaded.current = false; }
  }, [record['id']]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    dob,
    nin,
    email,
    gender,
    username,
    last_name,
    first_name,
    occupation,
    middle_name,
    nationality,
    income_status,
    marital_status,
    secondary_email,
    refugee_reg_number,
    secondary_phone_number
  } = record;

  const {
    villageid,
    villagename,
    parishname,
    subcountyname,
    countyname,
    districtname,
    regionname,
    physical_address
  } = location;

  const sex = !gender ? 'Male' : gender === 'M' ? 'Male' : 'Female';
  const phoneNumber = isNaN(Number(username))?'__':username;
  const phoneNumber2 = !secondary_phone_number?'__':secondary_phone_number;
  const email2 = !secondary_email?'__':secondary_email;
  const email1 = !email?'__':email;
  const maritalStatus = !marital_status?'__':marital_status;
  const Nationality = !nationality?'__':nationality;
  const income = !income_status?'__':income_status;
  const job = !occupation?'__':occupation;
  const idType = !nin?'__':'National ID';
  const NIN = !nin?'__':nin;

  React.useEffect(() => {
    if ( !!villagename ) { // mitigates "location" being populated
      const data = { ...location, ...record };
      sessionStorage.setItem('printable', JSON.stringify(data));
    };
  }, [villagename]); // eslint-disable-line react-hooks/exhaustive-deps

  // handle the modals' state
  const [open, setOpen] = React.useState({
    1: false, // userInfo
    2: false, // contactInfo
    3: false, // addressInfo
    4: false  // idInfo
  });

  // handle modals' open/close
  // @ts-ignore
  const handleClose = (arg: string | number) => {
    let updated = {};

    if (arg === 1)      updated = { 1: !open[1]}
    else if (arg === 2) updated = { 2: !open[2]}
    else if (arg === 3) updated = { 3: !open[3]}
    else                updated = { 4: !open[4]};
    
    setOpen({ ...open, ...updated })
  };

  // dynamic modal props
  const handleProps = (param: string | number) => {
    const detailProps = {
      record: record,
      isSmall: isSmall,
      open: open[param],
      handleClose: ()=>handleClose(param)
    };
    return detailProps;
  };

  // render "dynamic" user-title
  const userTitle = `User / 
    ${(matchUser && matchUser!.isExact)?'Farmer':(matchAccountant && matchAccountant!.isExact)?'Accountant':(matchAgent && matchAgent!.isExact)?'Agent':''}
  details`;

  return (
    <React.Suspense fallback={<FetchProgress title="Loading section..." top={true} bottom={true} /> }>
      <Box className="flex">
        <Box flex={1}>
          <SectionHeader
            isSmall={isSmall}
            label={userTitle}
            handleDialog={()=>handleClose(1)}
          /><hr/>
          <p><span>First name:</span> {first_name}</p>
          {!!middle_name && <p><span>Middle name:</span> {middle_name}</p>}
          <p><span>Last name:</span> {last_name}</p>
          <p><span>Gender:</span> {sex}</p>
          <p><span>Date of birth:</span> {dob && format(parseISO(dob), 'dd, MMM yyyy')}</p>
          <p><span>Marital status:</span> {maritalStatus}</p>
          <p><span>Occupation:</span> {job}</p>
          <p><span>Approx. Monthly Income:</span><br/><span className="height-0">{income}</span></p>
        </Box>
        <Box flex={1}>
          <SectionHeader
            btnLabel={villageid==='0'?'Add':''}
            isSmall={isSmall}
            label="Address info"
            handleDialog={()=>handleClose(3)}
          /><hr/>
          {!!villageid
            ? <>
                <p><span>Physical address:</span> {!!physical_address?physical_address:'__'}</p>
                <p><span>Village:</span> {!!villagename?villagename:'__'}</p>
                <p><span>Parish:</span> {!!parishname?parishname:'__'}</p>
                <p><span>Subcounty:</span> {!!subcountyname?subcountyname:'__'}</p>
                <p><span>County:</span> {!!countyname?countyname:'__'}</p>
                <p><span>District:</span> {!!districtname?districtname:'__'}</p>
                <p><span>Region:</span> {!!regionname?regionname:'__'}</p>
              </>
            : <FetchProgress title="loading..." top={true} />}
        </Box>
      </Box>
      <UserDetailsEdit {...handleProps(1)} />
      <AddressInfoEdit  {...handleProps(3)} profileMain={profileMain} />

      <Separator />
      <Separator />

      <Box className="flex">
        <Box flex={1}>
          <SectionHeader
            isSmall={isSmall}
            label="Contact info"
            handleDialog={()=>handleClose(2)}
          /><hr/>
          <p><span>Phone no:</span> {phoneNumber}</p>
          <p><span>Other Phone no:</span> {phoneNumber2}</p>
          <p><span>Email:</span> {email1}</p>
          <p><span>Other Email:</span> {email2}</p>
        </Box>
        <Box flex={1}>
          <SectionHeader
            isSmall={isSmall}
            label="Identification details"
            handleDialog={()=>handleClose(4)}
          /><hr/>
          <p><span>Identity type:</span> {idType}</p>
          {/* Make this field dependant on the idType - NIN, Permit no, Refugee no., Passport */}
          <p><span>NIN / Permint no:</span> {NIN}</p>
          {!!refugee_reg_number && <p><span>Refugee:</span> {refugee_reg_number}</p>}
          <p><span>Nationality:</span> {Nationality}</p>
        </Box>
      </Box>

      <ContactInfoEdit {...handleProps(2)} />
      <IdentityInfoEdit {...handleProps(4)} />

    </React.Suspense>
  )
};

const SectionHeader = props => {
  const { btnLabel, isSmall, label, handleDialog } = props;

  const NoIcon = () => null; // passed to "icon"
  const Words = () => <span>Edit</span>;

  return (
    <Box className='flex' alignItems={'flex-end'}>
      <SectionTitle label={label} className="mb-0" />
      <TitleButton
        handleNext={handleDialog}
        label={!btnLabel?'Edit':btnLabel}
        icon={isSmall?<Words />:<NoIcon />}
        className={clsx('ml-4','small--btn')}
      />
    </Box>
  );
};
