import { AuthProvider } from 'ra-core';
import { BASE_URL } from './modules';

const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    const url = BASE_URL + '/token-auth/';

    const request = new Request(url, {
      method: 'POST',
      body: `username=${username}&password=${password}`,
      headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error('Wrong username or password. Try again!');
        }
        return response.json();
      })
      .then(({ token, user, user_programs, provider, cooperative_branch }) => {
        
        const { user_system_role } = user;
        
        const { results } = user_programs;

        // check if provider object is not empty
        let isProvider = Object.prototype.hasOwnProperty.call(provider, 'id');

        let permissions: Array<string> = [];

        // populate the permissions array
        user_system_role && user_system_role.map(
          value => permissions.push(value.key)
        );   

        // General key/values
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('id', user.id);
        sessionStorage.setItem('lastName', user.last_name);
        sessionStorage.setItem('firstName', user.first_name);
        sessionStorage.setItem('middleName', user.middle_name);
        sessionStorage.setItem('permissions', JSON.stringify(permissions));

        // Related to provider's services
        sessionStorage.setItem('hasServices', 'false');
        
        // User's program
        if(results && results.length !== 0){
          sessionStorage.setItem('userProgram', results[0]['program_name']) 
          sessionStorage.setItem('userPrograms', JSON.stringify(results)) 
          sessionStorage.setItem('userProgramId', results[0]['id'])
        };

        // If has provider id...
        if (isProvider) {
          const { providerid, providerlocationid } = provider;

          sessionStorage.setItem('providerId', providerid);
          sessionStorage.setItem('providerlocationid', providerlocationid);
        };

        // check if Group admin
        if (
          cooperative_branch 
            && cooperative_branch.results 
              && cooperative_branch.results[0]
        ) {
          sessionStorage.setItem('saccoId', cooperative_branch.results[0].id);
          sessionStorage.setItem('saccoName', cooperative_branch.results[0].sacco_name);
        };

      });
  },

  logout: () => {
    sessionStorage.clear();
    return Promise.resolve();
  },

  checkError: error => {
    const status = error.status;
    // We assert that permissions isn't 'null' (@typescript)
    let permissions = JSON.parse(sessionStorage.getItem('permissions')!);

    if (status === 401 || status === 403) {
      if (permissions !== undefined) {
        return Promise.resolve();
      }
      sessionStorage.clear();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () =>
    sessionStorage.getItem('token') ? Promise.resolve() : Promise.reject(),

  getPermissions: () => {
    // We assert that permissions isn't 'null' (@typescript)
    let permissions = JSON.parse(sessionStorage.getItem('permissions')!);

    return permissions
      ? Promise.resolve(permissions)
      : Promise.reject('Unknown method');
  },
};

export default authProvider;
