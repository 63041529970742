import React from 'react';
import { Admin, fetchUtils, Resource } from 'react-admin';

import i18nProvider from 'i18n';
import AbLoginPage from 'login';
import { AbLayout } from 'layout';
import { Dashboard } from 'dashboard';
import CacheBuster from 'cacheBuster';
import drfProvider from 'dataProvider';
import authProvider from 'authProvider';
import customRoutes from 'customRoutes';

import {
  BASE_URL,
  users,
  loans,
  pos,
  sms,
  groups,
  reports,
  requests,
  settings,
  providers,
  cooperativelist,
} from 'modules';

import { AppContext } from 'context/app';

const App = () => {
  const [dataProvider, setDataProvider] = React.useState<null | any>(null);

  const [switchCardProgram, setSwitchCardProgram] = React.useState<boolean>(false); // update dashboard cards
  const [switchChartsProgram, setSwitchChartsProgram] = React.useState<boolean>(false); // update dashboard charts
  const [cropSidemenu, setCropSidemenu] = React.useState<boolean>(false); // manage farmer crop sidemenu

  const fetchDataProvider = async () => {
    const httpClient = (url: string, options: { headers: any }): any => {
      if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
      }
      const token = sessionStorage.getItem('token');
      options.headers.set('Authorization', `Bearer ${token}`);
      return fetchUtils.fetchJson(url, options);
    };

    let dataProviderInstance = await drfProvider( BASE_URL, httpClient );

    setDataProvider(
      // NOTE: dataProviderInstance can be a function
      () => dataProviderInstance
    );
  };

  React.useEffect(() => {
    fetchDataProvider();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!dataProvider) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  };

  const contextProps = {
    cropSidemenu: cropSidemenu,
    setCropSidemenu: setCropSidemenu,
    switchCardProgram: switchCardProgram,
    switchChartsProgram: switchChartsProgram,
    setSwitchCardProgram: setSwitchCardProgram,
    setSwitchChartsProgram: setSwitchChartsProgram,
  };

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        };

        return (
          <AppContext.Provider value={{ ...contextProps }}>
            <Admin
              title=""
              layout={AbLayout}
              dashboard={Dashboard}
              loginPage={AbLoginPage}
              dataProvider={dataProvider}
              authProvider={authProvider}
              i18nProvider={i18nProvider}
              customRoutes={customRoutes}                                                      /* see: dataProvider               */
            >
              {/* users */}
              <Resource name="users" {...users.farmers} />
              <Resource name="agents" {...users.agents}                                        /* -> "providerusersview & users"  */ />    
              <Resource name="members" {...users.groupmembers}                                 /* -> "cooperativebranchusers"     */ />
              <Resource name="accountants" {...users.accountants}                              /* -> "users"                      */ />
              <Resource name="coordinators" {...users.coordinators}                            /* -> "users"                      */ />
              <Resource name="superagents" {...providers.superagents}                          /* -> "provider"                   */ />
              <Resource name="provider" {...providers.serviceproviders} />
              {/* group related */}
              <Resource name="groups" {...groups.coopbranches}                                 /* -> "cooperativebranches"        */ />
              {/* pos related */}
              <Resource name="inventory" {...pos.inventory}                                    /* -> ""              */ />
              <Resource name="sales" {...pos.sales}                                            /* -> ""              */ />
              {/* system orders/requests */}
              <Resource name="cordinated-orders" {...requests.cordinatedorders}                /* -> "saccorequests"              */ />
              <Resource name="aggregated-orders" {...requests.aggregatedorders}                /* -> "cooperativeaggrequests"     */ />
              <Resource name="credit-orders" {...requests.creditorders}                        /* -> "saccorequests"              */ />
              <Resource name="cash-orders" {...requests.cashorders}                            /* -> "providerrequests"           */ />
              <Resource name="systemfarmerrequest" />
              {/* system loans */}
              <Resource name="loan-applications" {...loans.loanpapps}                          /* -> ""              */ />
              <Resource name="loan-payments" {...loans.loanpays}                               /* -> ""              */ />
              <Resource name="loan-products" {...loans.loanproducts}                           /* -> ""              */ />
              {/* reports */} 
              <Resource name="farmersreport" {...reports.farmers}                              /* -> "farmerclassifications"      */ />
              <Resource name="cooperativesreport" {...cooperativelist} />
              <Resource name="providersreport" {...reports.serviceproviders}                   /* -> "providerclassifications"    */ />
              <Resource name="transactionsreport" {...reports.transactions.cards}              /* -> "transactionclassifications" */ />
              <Resource name="transactionsreport-details" {...reports.transactions.detail}     /* -> "transactionclassifications" */ />
              {/* sms */} 
              <Resource name="sms" {...sms.report}  />
              {/* settings */} 
              <Resource name="programs" {...settings.programs} />
              <Resource name="funders" {...settings.funders}                                   /* -> "funder"                     */ />
              {/* Other */}
              <Resource name="cooperativesreport-details" />
              <Resource name="importproviderservices" />
              <Resource name="farmer-service-season" />
              <Resource name="farmersreport-details" />
              <Resource name="locationviewsearch" />
              <Resource name="programcordinator" />
              <Resource name="completerequest" />
              <Resource name="providerservice" />
              <Resource name="farmerservice" />
              <Resource name="usersaddress" />
              <Resource name="user-program" />
              <Resource name="usersview" />
              <Resource name="programs" />
            </Admin>
          </AppContext.Provider>
        );
      }}
    </CacheBuster>
  );
};

export default App;
