import React from 'react';
import { Link, useTranslate } from 'react-admin';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Box from '@material-ui/core/Box';

import { CardWraper2 } from '../../../layout';
import { cardStyles } from '../../../stylesheets/jss';


interface Props {
  value?: number;
  loading?: boolean;
}

export const TotalRequests: React.FC<Props> = ({ value, loading }) => {
  const translate = useTranslate();
  const classes = cardStyles();

  return (
    <Box flex={1} className={classes.bothSides}>
      <Link to={`/credit-orders`}>
        <CardWraper2
          value={`${value}`}
          loading={loading}
          title={translate('pos.dashboard.total_requests')} 
          icon={ShoppingCartIcon} 
        />
      </Link>
    </Box>
  );
};
