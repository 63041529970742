import React, { FC } from 'react';
import { Link } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps, Group } from '../../../../types';

const useStyles = makeStyles({
  root: {
    color: '#3f51b5',
  },
});

export const GroupLinkField: FC<FieldProps<Group>> = props => {
  const classes = useStyles();
  
  const { record, resource } = props;


	return record ? (
    <Link to={`/${resource}/${record.id}`} className={classes.root}>
			{record.cooperative_branch_name}
    </Link>
  ) : null;
};

GroupLinkField.defaultProps = {
  source: 'cooperative_branch_name',
  label: 'Group Name',
};
