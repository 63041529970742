import React from 'react';
import clsx from 'clsx';
import { Card, Box, Divider } from '@material-ui/core';

import { CircularProgress } from '../loaders';

interface Props {
  classes: any;
  title?: string;
  loading?: boolean;
  icon: React.FC<any>;
  switching?: boolean;
  subtitle?: string | number;
  children?: React.ReactNode;
}

const CardWithIcon: React.FC<Props> = props => {
  const { classes, icon, loading, switching, title, subtitle, children } = props;
  
  return (
    <Card className={classes.card}>
      <div className={classes.main}>
        <Box className={classes.icon}>
          {React.createElement(icon, { fontSize: 'large' })}
        </Box>
        <Box textAlign="right">
          <div className={classes.title}>{title}</div>
          {(loading || switching)
            ? <CircularProgress className={clsx('sm-wide')} />
            : <div className={classes.subT}>{subtitle || ' '}</div>
          }          
        </Box>
      </div>
      {children && <Divider />}
      {children}
    </Card>
  );
};

export default CardWithIcon;