import React from 'react';
import { stringify } from 'query-string';
import NewMembersIcon from '@material-ui/icons/PersonAdd';
import GeneralUsers from '@material-ui/icons/PeopleAltOutlined';
import { Box, Card, List, Avatar, Divider, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';

import { useTranslate, usePermissions, Link } from 'react-admin';

import { CardWraper1 } from '../../../layout';
import { apiFullCall } from '../../../modules';
import { cardStyles } from '../../../stylesheets/jss/';

import * as P from '../../../allPermissions';

interface CountStats {
  saccousers?: number;
  newSaccoUsers: number;
  newSaccoUserId: any;
  users: [];
}

interface Props {
  value?: number;
}

export const MemberList: React.FC<Props> = ({ value }) => {
  const classes = cardStyles();
  const translate = useTranslate();
  const { permissions } = usePermissions();

  const [state, setState] = React.useState<CountStats>({
    saccousers: 0,
    newSaccoUsers: 0,
    newSaccoUserId: '',
    users: []
  });

  const userIsPermitted = P.superuser(permissions) || P.admin(permissions);

  const token = sessionStorage.getItem('token');
  let isLoaded = React.useRef(true);

  /* fetch branch-users */

  React.useEffect(() => {

    if (isLoaded && userIsPermitted) {

      apiFullCall(
        '',
        token, 
        'get', 
        `cooperativebranchusers/`
      ).then(res => {
  
        const { body } = res;

        const { results: members } = body;

        // ALL memebers
        const allSaccoMembers = members.filter(val => val.cooperative_branch_id === 'AkelloBanker');

        // NEW memebers - not yet approved
        const newSaccoMembers = members.filter(val => val.approved === false);
 
        // all memebers
        newSaccoMembers && newSaccoMembers.map(val => {
        
          apiFullCall(
            '',
            token, 
            'get', 
            `users/${val.user_id}/`
          ).then(res => {

            const { status, body } = res;

            if (status === 200 || status === 201) {
    
              let users_list: any = state.users;

              if (
                !(users_list.filter(function(e) { return e.id === body.id; }).length > 0)
              ) {
                /** 
                 * Add "user" 
                 * only if not already in list 
                 */
                users_list.push(body)
              };
    
              setState(state => ({ ...state, users: users_list }));

            };

          }).catch(error =>
            console.error('Error while fetching "each user":', error)
          );

          return null;
        });
    
        setState(state => ({
          ...state,
          saccousers: allSaccoMembers.length,
          newSaccoUsers: newSaccoMembers.length,
          newSaccoUserId: newSaccoMembers,
        }));
  
      }).catch(error =>
        console.error('Error while fetching "branch-users":', error)
      );

    };

    // clean up API call, on unmount
    return function cleanup() { 
      isLoaded.current = false; 
    };

  }, [isLoaded, userIsPermitted]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    newSaccoUsers, 
    users 
  } = state;
  
  const commonProps = {
    classes: classes,
    translate: translate,
  };
  
  const moreProps = {
    newSaccoUsers: newSaccoUsers,
    users: users,
    ...commonProps,
  };

  return (
    <div className={classes.main}>
      <CardWraper1
        value={`${newSaccoUsers}`} 
        title={translate('pos.dashboard.new_members')} 
        icon={NewMembersIcon}
        children={<NewMemberList {...moreProps} />}
      />
    </div>
  );
};

export const MemberCount = props => {

  const { classes, memberCount, translate } = props;

  return (
    <Box flex={1} className={classes.left}>
      <Link to={`/members`}>
        <CardWraper1
          value={`${memberCount ? memberCount : 0}`} 
          title={translate('pos.dashboard.all_members')} 
          icon={GeneralUsers}
        />
      </Link>
    </Box>
  )
}

const NewMemberList = props => {

  const { classes, newSaccoUsers, translate, users } = props;

  return (
    <Card className={classes.card}>
      <Link
        to={{
          pathname: `/cooperativebranchusers`,
          search: stringify({
            page: 1,
            perPage: 25,
            sort: 'id',
            order: 'DESC',
            filter: JSON.stringify({ is_farmer: true }),
          }),
        }}
      >
        <Typography className={classes.title} color="textSecondary">
          {translate('pos.dashboard.new_sacco_member')}
        </Typography>
        <Typography variant="h5" component="h2" className={classes.value}>
          {newSaccoUsers}
        </Typography>
      </Link>
      <Divider />
      <List>
        {users
          ? users.map((record: any) => {
              return (
                <ListItem
                  button
                  to={`/users/${record.data['id']}`}
                  component={Link}
                  key={record.id}
                >
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText primary={record.data['first_name']} />
                  <ListItemText primary={record.data['last_name']} />
                </ListItem>
              );
            })
          : null}
      </List>
    </Card>
  )
};
