import React, { FC } from 'react';
import { ReferenceField } from 'react-admin';

import { FullNameField } from '../../users/userfields';

import { FieldProps } from '../../../types';

export const ProviderReferenceField: FC<FieldProps> = (props) => {

	const {record} = props;

	if (!record) {
		return null;
	};

	return(
		<ReferenceField source="added_by" reference="users" link={false} {...props}>
			<FullNameField />
		</ReferenceField>
	);
}

ProviderReferenceField.defaultProps = {
	addLabel: true,
	label: 'Reg. by'
};
