import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { Box } from '@material-ui/core';

import { SectionTitle } from 'modules/utils';

export const DetailFields = (props: any) => {

  const { record } = props;

  const {
    username,
    is_active,
    date_added,
    user_number,
    registered_by,
    user_system_role,
    registered_by_full_name
  } = record;

  const Status = is_active ? "Active" : "Inactive";
  const _role = user_system_role && user_system_role[0] && user_system_role[0]['name'];
  const userType = _role && `${_role[0].toUpperCase()}${_role.slice(1)}`

  return (
    <>
      <SectionTitle label="Brief" /><hr />
      <Box>
        <p><span>User status:</span> {Status}</p>
        <p><span>Joined:</span> {date_added && format(parseISO(date_added), 'dd, MMM yyyy')}</p>
        <p><span>User / Member no:</span> {user_number}</p>
        <p><span>Username:</span> {username}</p>
        <p><span>User type:</span> {userType}</p>
        {!registered_by
          ? <p><span>Auto-registered</span></p>
          : <p><span>Registered by:</span> {registered_by_full_name}</p>}
      </Box>
    </>
  )
};
