import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
    justifyContent: 'center',
  },
  chip: { margin: 4 },
});

export const MemberIsActiveField = ({ record }) => {
  const classes = useStyles();

	let isActive = record && record.approved ? "Yes" : "No";

  if (!record) {
    return null;
  };

  return (
    <span className={classes.main}>
      <Chip label={isActive} className={classes.chip} />
    </span>
  );
};

MemberIsActiveField.defaultProps = {
  label: 'Active',
  textAlign: 'center',
};
