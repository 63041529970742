import React from 'react';
import clsx from 'clsx';
import { Card, Box, Divider } from '@material-ui/core';

import { CircularProgress } from '../loaders';

interface Props {
  classes: any;
  title?: string;
  loading?: boolean;
  icon: React.FC<any>;
  switching?: boolean;
  subtitle?: string | number;
  subtitle2?: string | number;
}

const CardWithIcon2: React.FC<Props> = props => {
  const { classes, icon, loading, switching, title, subtitle, children, subtitle2 } = props;

  return (
    <Card className={classes.card}>
      <div className={classes.main}>
        <Box className={clsx(classes.icon, classes.icon2)}>
          {React.createElement(icon, { fontSize: 'large' })}
        </Box>
        <Box textAlign="right">
          <div className={classes.title}>{title}</div>
          {(loading || switching)
            ? <CircularProgress className={clsx('sm-wide')} />
            : <>
                <span className={classes.subT}>{subtitle || ' '}</span><br/>
                <span className={classes.subT}>{subtitle2 || ' '}</span>
              </>
          }          
        </Box>
      </div>
      {children && <Divider />}
      {children}
    </Card>
  );
};

export default CardWithIcon2;