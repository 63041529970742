import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { FullNameField, GenderField, CustomBooleanField } from './CustomFields';

const FarmerReportList = props => {
  const { location } = props;
  const { state } = location && location;

  const resource = 'farmersreport-details';
  const basePath = `/${resource}`;

  // These are set when the card are loaded
  const farmerClassification = sessionStorage.getItem('_farmers_classification');
  const farmersFilter = sessionStorage.getItem('_farmers_filter');

  const [filters, setfilters] = React.useState({}); // eslint-disable-line
  const [farmerclass, setClassification] = React.useState({}); // eslint-disable-line

  let isLoaded = React.useRef(true);

  React.useEffect(() => {

    if (isLoaded) {

      var classification = state ? state.farmerclass : JSON.parse(farmerClassification);
      var farmerfilters = state ? state.filters : JSON.parse(farmersFilter);
  
      setfilters(farmerfilters);
      setClassification(classification);

    };

    // clean up API call, on unmount
    return function cleanup() {
      isLoaded.current = false;
    };

  }, [isLoaded, filters, state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <List
      {...props}
      perPage={15}
      resource={resource}
      basePath={basePath}
      title="Farmers Report"
      filterDefaultValues={{ /* mitigate to avoid "Not found" */
        filter_1: filters.filter_1 ? filters.filter_1 : 'none',
        filter_2: filters.filter_2 ? filters.filter_2 : 0,
        filter_3: filters.filter_3 ? filters.filter_3 : 'none',
        filter_4: filters.filter_4 ? filters.filter_4 : 'none',
        filter_5: filters.filter_5 ? filters.filter_5 : 'none',
        filter_6: farmerclass.id ? farmerclass.id: 'A',
      }}
    >
      <Datagrid
        {...props}
        resource={resource}
        basePath={basePath}
      >
        <FullNameField source="first_name" label="Name" />
        <GenderField source="gender" label="Gender" />
        <TextField source="username" label="Contact" />
        <TextField source="user_number" label="User Id" />
        <DateField source="date_added" label="Registered" showTime />
        <CustomBooleanField source="is_active" label="Active" />
      </Datagrid>
    </List>
  );
};

export default FarmerReportList;