import React from 'react';
import { Login } from 'react-admin';
import { LoginForm } from './LoginForm';

import { AbTheme, loginStyles } from '../stylesheets';

const AbLoginPage = (props: any) => {
  const classes = loginStyles();

  return (
    <Login theme={AbTheme} className={classes.login}>
      <LoginForm />
    </Login>
  );
};

export default AbLoginPage;
