import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Layout, Sidebar } from 'react-admin';
import { AbAppBar } from './bars/AppBar';
import { Menu } from './menus';

import { AppContext } from '../context/app';

import { AbTheme, layoutStyles, sidebarStyles } from '../stylesheets';

export const AbLayout = (props: any) => {
  const classes = layoutStyles();
  const history = useHistory();

  const { cropSidemenu } = React.useContext(AppContext);

  return (
    <Layout
      {...props}
      appBar={AbAppBar}
      sidebar={CustomSidebar}
      menu={Menu}
      theme={AbTheme}
      className={clsx(
        classes.root, { 
          "my-profile": history.location.hash.includes(`#my-profile`) || history.location.hash.includes(`#my-wallet`),
          "farmer-info": cropSidemenu,
        }
      )}
    />
  );
};

const CustomSidebar = (props: any) => {
  const classes = sidebarStyles();
  const history = useHistory();

  const { cropSidemenu } = React.useContext(AppContext);  

  return (
    <Sidebar
      {...props}
      size={200} 
      className={clsx(
        classes.root, { 
          "my-profile": history.location.hash.includes(`#my-profile`) || history.location.hash.includes(`#my-wallet`),
          "farmer-info": cropSidemenu,
        }
      )}
    />
  );
};
