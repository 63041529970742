import React, { FC } from 'react';
import CardWithIcon from './CardWithIcon';
import CardWithIcon2 from './CardWithIcon2';
import { makeStyles } from '@material-ui/core/styles';

import { AppContext } from '../../context/app';

interface Props {
  icon: FC<any>;
  title: string;
  value?: string;
  value2?: string;
  loading?: boolean;
}

const useStyles = makeStyles(theme => ({
  card: {
    minHeight: '110px !important',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  main: {
    padding: 16,
    overflow: 'inherit',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  icon: {
    color: theme.palette.type === 'dark' ? 'inherit' : 'white',
    backgroundColor: '#3f51b5',
    borderRadius: '100%',
    height: '2.5em',
    width: '2.5em',
    padding: '8px',
    '& svg': {
      marginLeft: '2.25px'
    }
  },
  icon2: {
    '& svg': {
      marginTop: '2.25px'
    }
  },
  title: {
    fontSize: '.875rem',
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  subT: {
    fontSize: '160%',
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export const CardWraper1: FC<Props> = (props: any) => {
  const classes = useStyles(props);
  const { value, title, loading, icon } = props;

  const { switchCardProgram } = React.useContext(AppContext);

  return (
    <CardWithIcon
      icon={icon}
      title={title}
      subtitle={value}
      loading={loading}
      classes={classes}
      switching={switchCardProgram}
    />
  );
};

export const CardWraper2: FC<Props> = (props: any) => {
  const classes = useStyles(props);
  const { value, title, loading, icon, value2 } = props;

  const { switchCardProgram } = React.useContext(AppContext);

  return (
    <CardWithIcon2
      icon={icon}
      title={title}
      subtitle={value}
      loading={loading}
      classes={classes}
      subtitle2={value2}
      switching={switchCardProgram}
    />
  );
};

const wrappers = { 
  CardWraper1, 
  CardWraper2 
};

export default wrappers;
