import React from 'react';
import clsx from 'clsx';
import { stringify } from 'query-string';
import { makeStyles } from '@material-ui/core';

import { LinkButton } from 'layout';
import { LinkToRelatedListStyles } from 'modules/stylesheets';

const useStyles = makeStyles(() => ({
  root: {
    padding: '2px 0',
    fontSize: '0.925rem',
    backgroundColor: `rgba(63, 81, 181, 0.08) !important`,
  },
}));

export const LinkToListField = (props: any) => {
  const classes = LinkToRelatedListStyles();
  const moreClasses = useStyles();

  const programid = sessionStorage.getItem('userProgramId');

  const { record } = props;

  // total members in group
  const MemberCount = () => record && record.cooperative_branch ? <span>{record.cooperative_branch.length}</span> : <span>--</span>;

  const branchId = record && record['id'];
  const cooperative_branch_name = record && record['cooperative_branch_name'];  

  // Assumption: we use current program in session
  const cooperative_program = programid;

  if (!record) {
    return null;
  };

  const URL = {
    pathname: '/members/',
    search: stringify({
      page: 1,
      perPage: 25,
      sort: 'id',
      order: 'DESC',
      filter: JSON.stringify({ 
        cooperative_branch_id: branchId,
        /* same query fields as in "members" list */
        status: "users",
        cooperative_program: cooperative_program // programid
      }),
    }),
    state: { groupName: cooperative_branch_name },
  };

  return (
    <LinkButton
      url={URL}
      size='medium'
      className={clsx(classes.link, moreClasses.root)}
      label={<MemberCount />}
    />
  );
};

LinkToListField.defaultProps = {
  label: 'Members',
  textAlign: 'center',
};