import React from 'react';
import moment from "moment";
import { List, CardActions, useNotify } from 'react-admin';
import { Paper, Select, MenuItem, TextField, InputLabel, FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CooperativeReportCard from './CooperativeReportCard';
import { apiFullCall } from 'modules/apiHelper';

import { serviceProvidersStyles } from 'modules/stylesheets';


const CooperativeReport = ({ classes, ...props }) => {
  const notify = useNotify();

  const token = sessionStorage.getItem('token');
  const programid = sessionStorage.getItem('userProgramId');

  const [districts, setDistricts] = React.useState([]);
  const [districtid, setDistrict] = React.useState('none');

  const [agents, setAgents] = React.useState([]);
  const [agentid, setAgent] = React.useState('none');

  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');

  const setSelectAgent = e => setAgent(e.target.value ? e.target.value : 'none');

  const setSelectDistrictValue = (district) => {
    setDistrict(district ? district.districtid : 'none')
    if (district) {
      fetchAgent(`is_agent=${true}&districtid=${district.districtid}`)
    } else {
      fetchAgent(`is_agent=${true}`)
    }
  }

  const setDates = e => {
    if (e.target.value) {
      if (e.target.name === "startDate") {
        setStartDate(e.target.value)
      }
      else {
        setEndDate(e.target.value)
      }
    }
  }

  const setfuncDistrictValue = (newInputValue) => {

    if (newInputValue.length >= 3) {
      apiFullCall(
        '',
        sessionStorage.getItem('token'),
        'get',
        `district/?search=${newInputValue}`
      ).then(res => {
        const { status, body } = res;

        if (status === 200) {
          setDistricts(body.results)
        } else {
          notify(`Experienced an error, please try again later.`, 'warning')
        };

      }).catch(
        error => console.error('Error while getting districts:', error)
      );
    };

  };

  const fetchAgent = (parameters) => {

    apiFullCall(
      '',
      token,
      'get',
      `providerclassifications/?${parameters}`
    ).then(res => {
      const { status, body } = res;

      if (status === 200) {
        setAgents(body.results)
      } else {
        notify(`Experienced an error, please try again later.`, 'warning')
      };

    }).catch(
      error => console.error('Error while getting agents:', error)
    );
  };

  React.useEffect(() => {

    apiFullCall(
      '',
      token,
      'get',
      `farmerclassifications/?is_agent=${true}&filter_2=${programid}`
    ).then(res => {
      const { status, body } = res;

      if (status === 200) {
        setAgents(body.results)
      } else {
        notify(`Experienced an error, please try again later.`, 'warning')
      };

    }).catch(
      error => console.error('Error while getting agents:', error)
    );

  }, [programid]); // eslint-disable-line react-hooks/exhaustive-deps

  const moreProps = {
    agents: agents,
    agent: agentid,
    endDate: endDate,
    setDates: setDates,
    startDate: startDate,
    districts: districts,
    programid: programid,
    setDistricts: setDistricts,
    setSelectAgent: setSelectAgent,
    setfuncDistrictValue: setfuncDistrictValue,
    setSelectDistrictValue: setSelectDistrictValue,
  };

  const filterProps = {
    filter_1: districtid,
    filter_2: programid,
    filter_3: agentid, 
    filter_4: startDate ? startDate : 'none', 
    filter_5: endDate ? endDate : 'none'
  };

  return (
    <>
      <CooperativesFilters {...moreProps} />
      <List
        {...props} 
        pagination={false} 
        actions={<NoneActions />}
        title="Cooperatives/ Saccos/ Farmer groups" 
        filterDefaultValues={{ ...filterProps }} 
      >
        <CooperativeReportCard filters={{ ...filterProps }} {...props} />
      </List>
      {(districtid !== 'none' || programid !== 'none' || agentid !== 'none' || startDate !== '' || endDate !== '') &&
        <Paper>
          <div style={{ textAlign: 'center' }}>
            <p>
              <i>Applied filters:
                  <span>
                  <b>{`
                    ${districtid !== 'none' ? 'District ,' : ''} 
                    ${programid !== 'none' ? 'Program ,' : ''} 
                    ${agentid !== 'none' ? 'Agent ,' : ''} 
                    ${startDate !== '' ? 'Start date ,' : ''} 
                    ${endDate !== '' ? 'End date' : ''}
                  `}</b>
                </span>
              </i>
            </p>
          </div>
        </Paper>
      }
    </>
  );
};

const CooperativesFilters = ({ 
  programid,
  endDate,
  startDate,
  setDates,
  agents,
  agent,
  districts,
  setDistricts,
  setSelectAgent,
  setfuncDistrictValue,
  setSelectDistrictValue 
}) => {

  const classes = serviceProvidersStyles();

  return (
    <form noValidate autoComplete="off" style={{ width: '100%', marginTop: '30px', marginBottom: '-30px' }}>
      <Autocomplete
        id="country-select-demo"
        style={{ width: 224, float: 'left', marginLeft: '10px' }}
        options={districts}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.districtname}
        onChange={(event, newValue) => {
          event.preventDefault();
          setDistricts(newValue ? [newValue, ...districts] : districts);
          setSelectDistrictValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setfuncDistrictValue(newInputValue);
        }}
        renderOption={(option) => (
          <React.Fragment>
            {option.districtname} ({option.region})
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search District"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <TextField id="outlined-basic" label="Start date"
        value={startDate}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          max: moment().format('YYYY-MM-DD')
        }}
        type="date" onChange={setDates} name="startDate" variant="outlined" style={{ float: 'left', marginLeft: '10px' }} />
      <TextField id="outlined-basic" label="End date"
        value={endDate}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          max: moment().format('YYYY-MM-DD')
        }}
        type="date" onChange={setDates} name="endDate" variant="outlined" style={{ float: 'left', marginLeft: '10px' }} />
      <FormControl variant="outlined" style={{ float: 'left', minWidth: 220, marginLeft: '10px' }}>
        <InputLabel id="demo-simple-select-outlined-label">Agent</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={agent}
          onChange={setSelectAgent}
          label="Agent"
        >
          <MenuItem value="none">
            <em>Select</em>
          </MenuItem>
          {agents.map((agent, index) => <MenuItem key={index} value={agent.id}>{agent.fullname}</MenuItem>
          )}
        </Select>
      </FormControl>
    </form>
  );
};

const NoneActions = props => (
  <CardActions />
);

export default CooperativeReport;
