import React from 'react';
import clsx from 'clsx';
import CancelIcon from '@material-ui/icons/Cancel';
import { useInput, useTranslate } from 'react-admin';
import { InputAdornment, IconButton, Box, TextField, Typography, Slide } from '@material-ui/core';

export const Separator = () => <Box pt={{ xs: '0em', md: '1em' }} />;

export const SectionTitle = ({ ...props }) => {
  const translate = useTranslate();
  const { label, className, show } = props;

  return (
    <Typography variant="h6" className={className} gutterBottom>
      {translate(label)}
      {show && show}
    </Typography>
  );
};

// As used dialog slide-up
export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const trim = x => {
  // Search for any spaces
  // from the beginning of the string and from the end of string.
  // If found then, replace it with empty string ''.
  //
  return x.replace(/^\s+|\s+$/gm, '');
};

export const filterObject = (param1, param2) => {
  /**
   * This answer saved the day; https://stackoverflow.com/a/37616104/10849438
   * 
   * Question:
   * ECMAScript 5 has the filter() prototype for Array types, but not Object types, if I understand correctly.
   * How would I implement a filter() for Objects in JavaScript?
   * 
   * Using map() and spread syntax instead of reduce()
   * Here we move the Object.assign call out of the loop, so it is only made once, 
   * and pass it the individual keys as separate arguments (using the spread syntax):
   * 
   * @param {*} obj; Object
   * @param {*} predicate; function
   */
  Object.filter = (obj = param1, predicate = param2) =>
    Object.assign(...Object.keys(obj)
      .filter(key => predicate(obj[key]))
      .map(key => ({ [key]: obj[key] })));

  return Object.filter();
};

// input fields

export const AkbInput = props => {
  /**
   * Used within the Downshift (typeahead)
   * Where it's in use?
   * @providers /form/ProviderFormPersonWithAddress
   * @users /form/UserFormAddressWork
   * @dashboard /superuser/Filters -> see "resettable"
   */
  const { InputProps, classes, ref, show, ...other } = props;

  const { onClear, onReset, ...rest } = InputProps;

  const onClickIcon = e => {
    e.preventDefault();
    onClear();
    onReset();
  };

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
        },
        endAdornment: show ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="reset field"
              onClick={onClickIcon}
              onMouseDown={onClickIcon}
            >
              {show ? <CancelIcon /> : null}
            </IconButton>
          </InputAdornment>
        ) : null,
        ...rest,
      }}
      {...other}
    />
  );
};

export const AkbTextField = props => {
  /**
   * Customized Material-ui fields to follow react-admin design
   * Where it's in use?
   * @providers/form/ProviderFormPersonWithAddress
   * @users/form/UserFormAddressWork
   */
  const {
    input: { name, onChange },  
    meta: { error },
  } = useInput(props);

  const {
    className,
    disabled,
    helperText,
    label,
    placeholder,
    value,
  } = props;

  const [state, setState] = React.useState(true);

  const onBlur = e => setState(false); // onFocusOut
  const onFocus = e => setState(true); // onFocusIn

  return (
    <TextField
      name={name}
      value={value}
      label={label}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder}
      error={error && !state} // boolean
      helperText={error && !state && helperText} // show error
      className={clsx('AkbTextInput', className)}
    />
  );
};

export const convertToMillion = param => {
  var num = parseInt(param.replace(/,/g, ''));
  if (num >= 1000000) {
    var units = ["M+", "B+", "T+", "Q+"]
    var unit = Math.floor((num / 1.0e+1).toFixed(0).toString().length)
    var r = unit % 3
    var x = Math.abs(Number(num)) / Number('1.0e+' + (unit - r)).toFixed(2)
    return x.toFixed(2) + ' ' + units[Math.floor(unit / 3) - 2]
  } else {
    return param;
  }
};

export const isUpperCase = str => /^[A-Z]*$/.test(str);