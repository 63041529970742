import React from 'react';
import clsx from 'clsx';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';
import { Box, IconButton } from '@material-ui/core';
import { ImageInput, ImageField, TextInput } from 'react-admin';

import { DetailFields } from './DetailFields';
import { GeneralFields } from './GeneralFields';
import { AddToGroupFields } from './GroupFields';
import { PasswordFields } from './PasswordFields';
import { EnterpriseFields } from './EnterpriseFields';
import { AddCoordinatorDistrict } from './CoordinatorAreaFields';

import maleImage from 'assets/male.png';
import femaleImage from 'assets/female.png';
import { SectionTitle, Separator } from 'modules/utils';
import { UserProfilePDF } from 'modules/users/profile';
import { Text, Needed } from 'modules/validation';
import { NextButton as EditButton } from 'layout';

const UserImageEdit = React.lazy(() => import("../../userdialogs/userprofile/UserImageEdit"));

export const UserFields = (props: any) => {
  const {
    classes,
    isSmall,
    max1220,
    isRegistered,
    onlyEditFarmers,
    state,
    token,
    record,
    branchDetails,
    userRole,
    setUserRole,
    isFarmer,
    isCoordinator,
    filterValue,
    filterDistrict,
    getDistrict,
    downshiftOnChangeDistrict,
    inputOnChangeDistrict,
    downshiftOnChangeCooperative,
    inputOnChangeCooperative,
    onCancel,
    myProfile,
    profileMain,
    profilePassword,
    addOM
  } = props;

  // handle "profile picture" modal state
  const [openImage, setOpenImage] = React.useState(false);
  // handle "profile picture" modal open/close
  const handleCloseImage = () => setOpenImage(!openImage);

  const { image, gender } = record;

  const hasProfilePhoto = record && !!image;
  const Image = !!image ? image : gender && gender === "F" ? femaleImage : maleImage;

  /* general validation */
  const MemberRole = [ ...Text, ...Needed ];

  /* for general fields */
  const generalProps = {
    classes: classes,
    isRegistered: isRegistered
  }

  /* for password fields */
  const passfieldProps = {
    classes: classes,
    isSmall: isSmall, /* < 599.98px */
    token: token,
  };

  /* for coordinator district fields */
  const addCoordinatorProps = {
    classes: classes,
    getDistrict: getDistrict,
    filterDistrict: filterDistrict,
    inputOnChangeDistrict: inputOnChangeDistrict,
    downshiftOnChangeDistrict: downshiftOnChangeDistrict,
  };

  /* for add-to-group fields */
  const addGroupProps = {
    state: state,
    classes: classes,
    userRole: userRole,
    setUserRole: setUserRole,
    filterValue: filterValue,
    branchDetails: branchDetails,
    inputOnChangeCooperative: inputOnChangeCooperative,
    downshiftOnChangeCooperative: downshiftOnChangeCooperative,
  };

  const profileBtnProps = {
    classes: classes,
    openDialog: openImage,
    handleClose: handleCloseImage,
    userId: record && record['id'],
    hasProfilePhoto: hasProfilePhoto
  };

  const profilePhotoProps = {
    Image: Image, 
    record: record,
    isSmall: isSmall, 
    handleClose: handleCloseImage, 
  };

  const Export = () => <span>Export</span>;

  return (
    <>
      {/* if in create-mode */
        !isRegistered && 
          <Box display={{ xs: 'inline-flex' }} width={{ xs: '100% !important' }} mb={{ xs: '10px' }}>
            <Box flex={1}>
              <SectionTitle label="resources.users.fieldGroups.identity" className={clsx('AkRegisterForm-title')} />
            </Box>
            <Box>
              <IconButton onClick={onCancel}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Separator />
          </Box>
      }

      {/* if in my-profile */
        (myProfile || isRegistered) &&
          <Box display={{ xs: 'inline-flex' }} width={{ xs: '100% !important' }} mb={{ xs: '10px' }}>
            <Box flex={1} className={clsx('inline-flex', 'start')}>
              {(isSmall && profilePassword)
                /* if click "manage password", display "Change password" */
                ? <SectionTitle label="Manage password" className={clsx('AkRegisterForm-title')} />
                /* if click "my profile", display "User profile" */
                : <>
                    <SectionTitle label="User Profile" className={clsx('AkRegisterForm-title')} />
                    {!profilePassword &&
                      <PDFDownloadLink className="export-profile" document={<UserProfilePDF />} fileName="somename.pdf">
                        {({ blob, url, loading, error }) =>
                          loading 
                            ? 'Processing...'
                            : <EditButton
                                icon={isSmall ? <Export /> : <DownloadIcon />}
                                label='Export'
                                handleNext={()=>{}}
                              />}
                      </PDFDownloadLink>}
                  </>}
            </Box>
            <Box>
              <IconButton onClick={onCancel}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>      
      }

      {/* 
        * SimpleForm expects Input or Field components as children.
        * Explained here: https://marmelab.com/react-admin/CreateEdit.html#the-simpleform-component. 
        * So it's normal that you see warnings because we passed <Box>
        */}
      <Box
        display={{ md: 'flex' }}
        className={clsx(
          'AkRegisterForm-body',
          { 'reversed': myProfile && !profilePassword },
        )}
      >
        <Box
          flex={3}
          className={clsx(
            'detail-section',
            { 'mr-15-negative': !max1220 },
          )}
        >
          {/* if <[599.98px] and we click "manage password" */
            (isSmall && profilePassword)
              ? /* hide general fields, if yes */ null
              : /* show generic fields, if no  */ <GeneralFields {...generalProps} />}

          {/* if coordinator */ 
            isCoordinator && <AddCoordinatorDistrict {...addCoordinatorProps} />}

          {/* if sacco-admin, show this field at registration (not my-profile) */
            !myProfile && !isRegistered && addOM &&
              <TextInput
                source="role"
                className={classes.input}
                validate={MemberRole}
              />}

          {/* if <[599.98px], and user clicks "manage password" */
            (isSmall && profilePassword)
              ? /* hide the add-to-group fields   */ null
              : /* else if hasEdit rights */ onlyEditFarmers &&
                /* then show add-to-group fields  */
                <AddToGroupFields {...addGroupProps} />}

          {!isRegistered && isFarmer && max1220 &&
              <EnterpriseFields classes={classes} token={token} />}

        </Box>

        <Box
          flex={1}
          className={clsx(
            'AkRegisterForm-body',
            { 'add-to-district': !myProfile },
            { 'password-section': myProfile },
            { 'flex center-justify bg-grey--light mr-15 h-450': !isRegistered }
          )}
        >
          {!isRegistered
            ? <AddProfilePictureButton classes={classes} /> /* create-mode */
            : profilePassword  /* edit-mode */
              ? null /* if "password" manager, don't display "profile image" */
              : <RenderProfilePicture {...profilePhotoProps} />}

          {isRegistered /* confirm edit-mode */
            ? myProfile /* are we in my-profile? */
              ? profileMain /* Yes, it's my-profile */
                ? /* show details */ <DetailFields record={record} />
                : profilePassword
                  ? /* "password" manager */ <PasswordFields {...passfieldProps} />
                  : null
              /* Nope, we're editing other user (not my-profile) */
              : null
            /* render nothing if not in edit-mode */
            : null}

        </Box>

        <UserImageEdit {...profileBtnProps} /> {/* Modal triggered in "edit-mode" */}
      </Box>

      {!isRegistered && isFarmer && !max1220 &&
        <Box
          className={clsx('mt-120-negative')}>
          <EnterpriseFields classes={classes} token={token} />
        </Box>}

    </>
  )
};

const AddProfilePictureButton = ({ classes }) => {
  
  return (
    <div>
      <ImageInput 
        /* Show profile picture */
        label=""
        source="image"
        accept="image/*"
        placeholder={
          <Box
            mt={{ xs: '1em', md: '3.75em' }}
            mb={{ xs: '1em', md: '3.75em' }}
          >
            <span>Click here ...<br/>to add profile picture</span>
          </Box>}
        className={clsx(classes.photo, 'new-photo')}
      >
        <ImageField source="image" title="profile picture" />
      </ImageInput>
    </div>
  )
};

const RenderProfilePicture = props => {

  const { Image, isSmall, handleClose, hasProfilePhoto, record } = props;

  const NoIcon = () => null; // passed to "icon"

  let imageDiv: HTMLImageElement = document.getElementById("profile-photo") as HTMLImageElement;

  if (!!imageDiv) {
    // add "profile picture" as a background image 
    // imageDiv.style.backgroundColor = `#e66b57`;
    imageDiv.style.backgroundImage = `url(${record.image})`;
  };

  // if "mobile", don't show this...
  if (isSmall) {
    return null;
  };

  return (
    hasProfilePhoto
      ? <div className="flex align-items-center">
          <div className="previews placeholder">
            <div // render profile-picture                      
              id="profile-photo" 
              className="previews image fade-in"
            ></div>
          </div>
          <EditButton
            icon={<NoIcon />}
            label='Change picture'
            className="ml-4"
            handleNext={handleClose}
          />
        </div>
      : <>
          <EditButton
            icon={<NoIcon />}
            label='Add profile picture'
            handleNext={handleClose}
          />
          <Box className="flex align-items-center">
            <div className="previews placeholder">
              <img src={Image} alt="user" className="previews" />
            </div>                    
          </Box>
        </>
  )
};