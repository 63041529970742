import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import FullNameField from './FullNameField';
import { FieldProps, User } from '../../../../types';

import * as P from '../../../../allPermissions';

const useStyles = makeStyles({
  root: {
    color: '#3f51b5',
  },
});

export const UserLinkField: React.FC<FieldProps<User>> = props => {
  const classes = useStyles();
  const history = useHistory();
  const { permissions } = usePermissions();

  const { record, resource } = props;

  const loggedInUserId = sessionStorage.getItem('id');
  /* verify if currently logged in user */
  const currentUser = record && record['id'] === Number(loggedInUserId);
  
  const isFarmer = resource === "users";                // Farmer
  const isAgent = resource === "agents";                // agent
  const isMember = resource === "members";              // sacco-members
  
  const accessDeniedToFarmers = P.cantEditFarmers(permissions) && isFarmer;
  const accessDeniedToAgents = P.cantAddOrEditAgents(permissions) && isAgent;
  const accessDeniedToSaccoMembers = P.cantEditSaccoMembers(permissions) && isMember;

  const noLink = accessDeniedToFarmers || accessDeniedToAgents || accessDeniedToSaccoMembers;

  if (!record) {
    return null;
  };

  return noLink ? 
    <FullNameField {...props} />
  : currentUser
    ? <Link
        className={classes.root}
        to={{ 
          pathname: `/users/${record.id}`, 
          hash: '#my-profile', 
          state: { prevPath: history.location.pathname } 
        }}
      >
        <FullNameField {...props} />
      </Link>
    : <Link to={`/${resource}/${record.id}`} className={classes.root}><FullNameField {...props} /></Link>
};

UserLinkField.defaultProps = {
  source: 'id',
  label: 'resources.users.fields.name',
};