import { makeStyles } from '@material-ui/core/styles';

const maxMdWidth = 959.98; // md (down)
const maxSmWidth = 599.98; // sm (down)
const maxXSWidth = 431.98; // xsm (down)

export const dashboardStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  flex: {
    display: 'flex',
  },
  childDiv: {
    flex: 1,
    margin: '10px 20px 10px 10px',
    padding: '0px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  leftCol: {
    flex: 1,
    marginRight: '1em',
  },
  rightCol: {
    flex: 1,
    marginLeft: '1em',
  },
  singleCol: {
    marginTop: '2em',
    marginBottom: '2em',
  },
  membersStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '97%',
    borderRadius: '3px',
    margin: '30px auto',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    '&:hover': { boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)' },
    justifyContent: 'space-around',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '84.5%',
    },
  },
  dashboardHeader: {
    border: '1px solid blue',
  },
}));

export const cardStyles = makeStyles(theme => ({
  main: {
    flex: '1',
    paddingRight: '0.75em',
    paddingTop: 20,
  },
  upDown: {
    paddingTop: '1.25em',
    paddingBottom: '0.75em',
  },
  spaceTop: {
    [`@media (max-width: ${maxXSWidth}px)`]: {
      marginTop: '1em',
    },
  },
  right: {
    [`@media (min-width: ${maxXSWidth}px)`]: {
      paddingRight: '0.75em',
    },
    [`@media (max-width: ${maxXSWidth}px)`]: {
      paddingRight: '0',
    },
  },
  left: {
    paddingLeft: '0.75em',
  },
  bothSides: {
    paddingRight: '0.75em',
    paddingLeft: '0.75em',
  },
  flex: {
    display: 'flex',
  },
  flexChange: {
    [`@media (min-width: ${maxSmWidth}px)`]: {
      display: 'display',
    },
    [`@media (max-width: ${maxSmWidth}px)`]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  flexColumn: {
    display: 'flex',
    [`@media (max-width: ${maxXSWidth}px)`]: {
      flexDirection: 'column',
    },
  },
  leftCol: {
    flex: 4,
    [`@media (min-width: ${maxSmWidth}px)`]: {
      paddingRight: '0.75em',
    },
    [`@media (max-width: ${maxSmWidth}px)`]: {
      paddingRight: '0',
    },
  },
  rightCol: {
    flex: 1, 
    [`@media (min-width: ${maxSmWidth}px)`]: {
      paddingLeft: '0.75em',
    },
    [`@media (max-width: ${maxSmWidth}px)`]: {
      paddingLeft: '0',
    },
  },
  bothCol: {
    [`@media (min-width: ${maxSmWidth}px)`]: {
      paddingLeft: '0',
      paddingRight: '0',
    },
    [`@media (max-width: ${maxSmWidth}px)`]: {
      paddingLeft: '1.25em',
      paddingRight: '1.25em',
    },
  },
  rightCol2: {
    flex: 1, 
    paddingLeft: '0.75em',
  },
  singleCol: {
    paddingTop: '0.75em',
    paddingBottom: '0.75em',
  },
  media: {
    height: '18em',
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
  graphContainer: {
    padding: '15px',
  },
  value: {
    padding: '0 16px',
    minHeight: 48,
  },
  root: {
    margin: 0,
  },
  cost: {
    paddingRight: '0.75em',
    color: theme.palette.text.primary,
  },
  title: {
    padding: '16px 16px',
  },
  table: {
    minWidth: 650,
  },
  grid: {
    [`@media (min-width: ${maxMdWidth}px) `]: {
      width: '25%',
    },
    [`@media (max-width: ${maxMdWidth}px) `]: {
      flexBasis: '50% !important',
      maxWidth: '100% !important',
      padding: '5px !important',
    },
    [`@media (max-width: ${maxXSWidth}px) `]: {
      flexBasis: '100% !important',
    },
  },
  bothCol2: {
    [`@media (max-width: ${maxMdWidth}px)`]: {
      paddingLeft: '1.25em',
      paddingRight: '1.25em',
    },
  },
}));

export const moreStyles = makeStyles(theme => ({
  media: {
    height: '32em',
  },
  provider: {
    margin: '1em auto',
    [theme.breakpoints.down('xs')]: {
      margin: '1em',
    },
  },
  button: {
    fontSize: '0.95rem',
    color: '#3f51b5',
    backgroundColor: '#fff',
    padding: '6px 12px',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset',
    '&:hover, &:focus, &:active': {
      fontWeight: 'bold',
      color: '#fff',
      backgroundColor: '#3f51b5',
      boxShadow: 'none',
    },
    [`@media (min-width: ${maxSmWidth}px)`]: {
      marginTop: '25px',
    },
    [`@media (max-width: ${maxSmWidth}px)`]: {
      marginTop: '12px',
    },
  },
  farmerButton: {
    [`@media (min-width: ${maxSmWidth}px)`]: {
      marginRight: '12px',
    },
    [`@media (max-width: ${maxSmWidth}px)`]: {
      paddingLeft: '59px',
      paddingRight: '59px',
    },
  },
  btnContainer: {
    [`@media (min-width: ${maxSmWidth}px)`]: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      marginLeft: '50%',
    },      
  },
}));
