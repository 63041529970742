import { makeStyles } from '@material-ui/core/styles';
import LgImage from '../assets/akb_des.jpg';
import SmImage from '../assets/akb_mob.jpg';

const minWidth = 450;
const maxWidth = 449.99;

const loginStyles = makeStyles(
  () => ({
    /* Overidding ra-login styling
     * Ref: ra-ui-materialui/src/auth/Login.tsx
     */
    login: {
      backgroundPosition: 'top center',
      '& [class*=card]': {
        marginTop: '9.5em',
      },
      '& label[class*=focused]': {
        color: '#3f51b5',
      },
      '& [class*=underline]': {
        '&:not([class*=error])': {
          '&:after': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
          },
        },
        '&:hover, &:focus, &:active': {
          '&:not([class*=disabled])': {
            '&:before, &:after': {
              borderBottomColor: '#3f51b5',
            },
          },
        },
      },
      '& label[class*=error]': {
        color: '#f44336',
      },
    },
    [`@media (min-width: ${minWidth}px)`]: {
      login: {
        backgroundImage: `url(${LgImage})`,
      },
    },
    [`@media (max-width: ${maxWidth}px)`]: {
      login: {
        backgroundImage: `url(${SmImage})`,
        '& [class*=card]': {
          marginTop: '4em',
          minWidth: '250px',
        },
      },
    },
  }),
  { name: 'AkbMain' }
);

const layoutStyles = makeStyles(
  () => ({
    /* Overidding ra-layout styling
     * Ref: ra-ui-materialui/src/layout/Layout.js
     */
    root: {
      '& header': {
        '& [class*=MuiToolbar]': {
          '& > p[class*=MuiTypography]': {
            fontSize: '1.15rem',
            fontWeight: 600,
          }
        },
      },
      '& main': {
        '& > div:nth-of-type(2n)': {
          '& [class*=main]': {
            '& table': {
              '& > thead': {
                '& th': {
                  fontWeight: 'bold',
                },
              },
              '& > tbody': {
                '& .MuiTableCell-root': {
                  '& > a, & > a[class*=link]': {
                    color: '#3f51b5 !important',
                    '&[role=button]': {
                      '&:hover, &:focus': {
                        backgroundColor: 'rgba(63, 81, 181, 0.08) !important',
                      },
                    },
                    '& > span': {
                      color: '#3f51b5',
                    },
                  },
                  '& > a[class*=button]': {
                    '&:hover, &:focus': {
                      color: '#3f51b5 !important',
                      backgroundColor: 'rgba(63, 81, 181, 0.08) !important',
                    },
                  },
                },
                '& > [class*=clickableRow]': {
                  '&[class*=hover]': {
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.07)',
                    },
                  },
                },
              },
            },
            '& [class*=scroller]': {
              '& > span[class*=indicator]': {
                backgroundColor: '#3f51b5 !important',
              },
            },
          },
          '& form': {
            '& > .MuiToolbar-root': {
              '& .MuiButton-textPrimary': {
                backgroundColor: '#3f51b5 !important',
                color: '#eff0f1 !important',
                '&:hover, &:focus, &:active': {
                  backgroundColor: '#3f51b5 !important',
                  color: '#eff0f1 !important',
                  boxShadow: `0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)`,
                },
              },
            },
            '& [class*=filter-field]': {
              '& [class*=input]': {
                '& > label[class*=formControl]': {
                  '&:not([class*=error])': {
                    color: '#3f51b5',
                  },
                },
                '& > div[class*=formControl]': {
                  '& [class*=error]': {
                    '&:after': {
                      borderBottomColor: '#3f51b5',
                    },
                  },
                  '&:after': {
                    borderBottomColor: '#3f51b5',
                  },
                },
                /**
                 * Related to https://gitlab.com/akellobanker/akellobanker-web-app/-/issues/4
                 *
                 * Currently, we hide this error which is triggered by unintended api call
                 */
                '& > p[class*=contained]': {
                  display: 'none',
                },
              },
            },
            '& > [class*=content]': {
              '& [class*=container]': {
                '&[role=combobox]': {
                  display: 'inline-flex',
                },
              },
              '& > span': {
                '&:nth-child(4)': {
                  '& > div[class*=input]': {
                    '& > div': {
                      '&:nth-of-type(4), &:nth-of-type(5)': {
                        '& > div': {
                          width: 472,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  }),
  { name: 'AkbLayout' }
);

const sidebarStyles = makeStyles(
  theme => ({
    /* Overidding ra-layout styling
     * Ref: ra-ui-materialui/src/layout/Sidebar.js
     */
    root: {
      zIndex: 1000,
      '& a': {
        '&.MuiListItem-root': {
          color: '#3f51b5'
        },
        '& > .MuiListItemIcon-root': {
          color: '#3f51b5'
        },
      },
      '& .MuiListItem-button': {
        '&:hover, &:focus, &:active': {
          backgroundColor: 'rgba(63, 81, 181, 0.04)',
        },
      },
      '& .general-sidemenu' : {
        '& a': {
          '&:hover, &:focus, &:active': {
            color: '#3f51b5',
            fontWeight: '600',
          },
          '&[class*=active]': {
            fontWeight: '600',
          },
          '& [class*=icon]': {
            '&:hover, &:focus, &:active': {
              color: 'rgba(0, 0, 0, 0.65)',
            },
          },
        },
        '& .MuiListItemIcon-root': {
          color: '#3f51b5'
        }
      },
      '& .farmer-sidemenu' : {
        '& a': {
          '&[class*=active]': {
            color: 'rgba(0, 0, 0, 0.54)',
          },
          '&.activated': {
            fontWeight: '600',
          },
        },
      },
      '& .my-profile-sidemenu' : {
        '& a': {
          '&:hover, &:focus, &:active': {
            color: '#3f51b5',
          },
          '&:active': {
            fontWeight: '600',
          },
          '&[class*=active]': {
            color: '#3f51b5',
          },
          '&.activated': {
            fontWeight: '600',
            backgroundColor: '#fff',
            borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
            boxShadow: '0px 8px 15px -9px rgb(0 0 0 / 20%), 0px 24px 30px 15px rgb(0 0 0 / 2%), 0px 9px 46px 8px rgb(0 0 0 / 1%);',
          },
          '& [class*=icon]': {
            '&:hover, &:focus, &:active': {
              color: '#3f51b5',
            },
          },
        },
      },
      '& li': {
        '&[class*=button]': {
          '& > .MuiTypography-colorTextSecondary': {
            color: '#3f51b5'
          },
          '&:hover, &:focus': {
            fontWeight: '600',
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        '& > .MuiDrawer-paperAnchorLeft': {
          marginTop: '1.5em',
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& > .MuiDrawer-paperAnchorLeft': {
          minWidth: '75%',
        },
      },
    },
  }),
  { name: 'AkbSidebar' }
);

export { loginStyles, layoutStyles, sidebarStyles };
