import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import * as serviceWorker from './serviceWorker';
import './stylesheets/css/index.css';
import './stylesheets/css/dialog.css';

const SENTRY_STATUS = process.env.NODE_ENV === 'production' ? true : false;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  autoSessionTracking: SENTRY_STATUS,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 0.5,

  beforeSend: (event) => {
    if (
      // Exclude "localhost" and "mobile" envs from issue tracker
      window.location.hostname === 'localhost' || window.location.hostname === '192.168.0.134'
    ) {
      return null;
    }
    return event;
  },
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
