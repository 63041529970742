import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';
import { useMediaQuery } from '@material-ui/core';
import { SaveButton, SimpleForm, TextInput, Toolbar, useRefresh, useNotify } from 'react-admin';

import { limitText, Needed } from 'modules/validation';
import { apiFullCall } from 'modules/apiHelper';
import { ApproveButton as NoButton } from 'layout';

/* Used in <SmsButton> */

export const SmsCreate = props => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const [count, setCount] = React.useState(0);
  const limit = 280; // based on egoSMS

  const {
    handleDialogClose,
    unselectAll,
    selectedIds,
    ...rest
  } = props;

  // passed to <BulkSmsToolbar />
  const bulkSmsProps = {
    isXSmall: isXSmall,
    ...props
  };

  const restrict = [ ...Needed, ...limitText ];

  return (
    <SimpleForm
      {...rest}
      submitOnEnter={false}
      className={clsx( 'profile', 'message' )}
      toolbar={<BulkSmsToolbar {...bulkSmsProps} />}
    >
      <p className='counter'>
        <span className={clsx({ 'past': count > limit })}>{count}</span>/{limit} characters
      </p>
      <TextInput
        multiline
        source="message"
        validate={restrict}
        label="Enter your message:"
        onChange={e => setCount(e.target.value.length)}
      />
    </SimpleForm>
  );
};


/* Handling "send" bulk sms */


export const BulkSmsToolbar = props => {

  // destructure to pass directly to <NoButton>
  const { isXSmall, ...rest } = props;
  
  const { handleDialogClose, selectedIds, unselectAll, ...other } = rest;

  return (
    <Toolbar {...other}>
      <NoButton
        label="No"
        icon={null}
        isXSmall={isXSmall}
        className={clsx('small--btn')}
        onClick={handleDialogClose}
      />
      <SendButton {...rest} /> 
    </Toolbar>
  )  
};

const SendButton = props => {
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();

  // destructure to pass "rest" to <SaveButton>
  const {
    handleDialogClose,
    mutationMode, 
    record,
    resource,
    validating, 
    // from <SmsButton>
    unselectAll,
    selectedIds,
    ...rest 
  } = props;

  const token = sessionStorage.getItem('token');
  const programid = Number(sessionStorage.getItem('userProgramId'));

  const [ loading, setLoading ] = React.useState(false); // eslint-disable-line

  let isLoaded = React.useRef(false);

  React.useEffect(() => {
    /* Cleanup useEffect */

    isLoaded.current = true; // set to true on mount...
    return () => { isLoaded.current = false; }; // ... and to false on unmount
  }, [isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const { message } = formState && formState.values;

  const smsData = {
    "message": message, 
    "farmers": resource==="users"?selectedIds:[], 
    "program": resource==="programs"?selectedIds:[programid], 
    "saccos": resource==="groups"?selectedIds:[],
  };

  const handleSend = React.useCallback(() => {

    // Ensure we have a list of selected "items"
    if (!!selectedIds && selectedIds.length > 0) {

      const total = selectedIds && selectedIds.length;

      apiFullCall(
        smsData, 
        token, 
        'post', 
        `bulk-sms/` 
      ).then(res => {

        if (res) {

          if (isLoaded.current) setLoading(true);

          const { status } = res;

          if (status === 200 || status === 201) {

            // go back to requests list
            refresh();
            unselectAll(resource);

            // when successfully submitted
            notify(`Successfully sent ${total} message${total>1?'s':''}`, 'info');
            
          } else if (status === 400) {
            notify(`Please check again and resend.`, 'warning');
          } else if (status >= 500) {
            notify(`Server error, please try again later.`, 'warning');          
          };

          if (isLoaded.current) setLoading(false);

        };

      }).catch(
        error => console.error(`Error while sending message${total>1?'s':''}:`, error)
      );

    } else {

      // inform user to select a "item"
      notify(`Please select at least one item`, 'warning');
    };

  }, [smsData, selectedIds]); // eslint-disable-line react-hooks/exhaustive-deps

  // define a component that returns "null"
  // And pass it to <SaveButton> to override the "save" icon
  const NoIcon = () => null;

  return (
    <SaveButton
      {...rest}
      icon={<NoIcon />}
      label={loading ? "" : "Yes"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleSend} 
    />
  );
};
