import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';

import parseISO from 'date-fns/parseISO';
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
    justifyContent: 'center',
  },
  chip: { margin: 4 },
});

export const UserAgeField = ({ record }) => {
  const classes = useStyles();

  // returns "date-of-birth"
	let dateOfBirth = record && record.dob;
  let calculatedAge = differenceInCalendarYears(new Date(), parseISO(dateOfBirth));
  let userAge = !calculatedAge ? "---" : `${calculatedAge} yrs`;

  if (!record) {
    return null;
  }

  return (
    <span className={classes.main}>
      <Chip label={userAge} className={classes.chip} />
    </span>
  );
};

UserAgeField.defaultProps = {
  label: 'Age',
  textAlign: 'center',
};
