import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import GroupIcon from '@material-ui/icons/Group';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useTranslate, Link as CardLink } from 'react-admin';

import { CardWraper1, CardWraper2 } from '../../layout';
import { SuperuserDashboardContext } from '../../context';
import { Separator } from '../../modules';

import { cardStyles, moreStyles } from '../../stylesheets/jss';

export const Stats = () => {
  const classesBase = cardStyles();

  const { isSmall } = React.useContext(SuperuserDashboardContext);

  return isSmall ? (
    <Box mt={{ xs: '24px' }} className={classesBase.flex}>
      <Box className={clsx(classesBase.leftCol, classesBase.bothCol2)}>
        <RenderSuperuserTitle />
        <RenderSuperuserButtons />
        <RenderSuperuserCards />
      </Box>
      {!isSmall && <Box className={classesBase.rightCol}></Box>}
    </Box>
  ) : <RenderSuperuserCards />;
};

const RenderSuperuserTitle = () => {
  const translate = useTranslate();

  const firstName = sessionStorage.getItem('firstName');
  const lastName = sessionStorage.getItem('lastName');

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={10}>
        <Typography variant="h5" component="h2">
          {translate('pos.dashboard.superuser.title')} {firstName}&nbsp;{lastName}
        </Typography>
        <Separator />
        <Typography component="p">
          {translate('pos.dashboard.superuser.subtitle')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}></Grid>
    </Grid>
  );
};

const RenderSuperuserButtons = () => {
  const classes = moreStyles();
  const classesBase = cardStyles();
  const translate = useTranslate();

  const { isSmall } = React.useContext(SuperuserDashboardContext);

  return (
    <Box className={clsx(classesBase.flexColumn, classesBase.upDown )}>
      <Box flex={1} className={classesBase.flexChange}>
        <Button
          // farmer/user registration
          size="small"
          component={Link}
          to="/users/create"
          className={clsx(classes.button, classes.farmerButton)}
        >
          {translate('pos.dashboard.agent.farmer_button')}
        </Button>
      </Box>
      <Box flex={!isSmall ? 1.925 : 1} className={classesBase.flexChange}>
        <Button
          // provider registration
          size="small"
          component={Link}
          to="/provider/create"
          className={clsx(classes.button)}
        >
          {translate('pos.dashboard.agent.provider_button')}
        </Button>
      </Box>
    </Box>
  );
};

const RenderSuperuserCards = () => {
  const classesBase = cardStyles();

  const { loading, totals } = React.useContext(SuperuserDashboardContext);

  const { agents, farmers, providers, transactions, transactions_amount } = totals;

  return (
    <Box className={clsx('main-dashboard-cards')}>
      <Grid container spacing={3}>
        <Grid item xs={3} className={classesBase.grid}>
          <CardLink to={`/agents`}>
            <CardWraper1
              title="AGENTS"
              loading={loading}
              icon={PeopleOutlineIcon}
              value={agents}
            />
          </CardLink>
        </Grid>
        <Grid item xs={3} className={classesBase.grid}>
          <CardLink to={`/users`}>
            <CardWraper1
              title="FARMERS"
              loading={loading}
              icon={GroupIcon}
              value={farmers}
            />
          </CardLink>
        </Grid>
        <Grid item xs={3} className={classesBase.grid}>
          <CardLink to={`/provider`}>
            <CardWraper1
              title="SERVICE PROVIDERS"
              loading={loading}
              icon={TouchAppIcon}
              value={providers}
            />
          </CardLink>
        </Grid>
        <Grid item xs={3} className={classesBase.grid}>
          <CardWraper2
            title="TRANSACTIONS"
            loading={loading}
            icon={MonetizationOnIcon}
            value={transactions}
            value2={transactions_amount}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
